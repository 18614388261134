import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAllSales} from "store/slices/salesSlice";
import Breadcrumbs from '../@components/breadcrumbs';
import ModalPromotions from '../@components/modalPromotions';
import Loader from "../@components/loader";
import './promotions.scss';
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';


const Promotions = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalActiveItem, setModalActiveItem] = useState(null);
  function openModal(item) {
    setModalShow(true);
    setModalActiveItem(item);
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllSales())
  },[])
  const {sales, getAllSalesLoading} = useSelector((state) => state.sales)


  return (
    <>
      <div className='promotions'>
        <div className='container'>
          <Breadcrumbs />
          {
            getAllSalesLoading === 'loading' ? <Loader withHeight="230"/> : (
                sales.length > 0 ? (
                    <div className='promotions-grid'>
                      {sales.map(el => (
                          <div className='promotions-grid__item' key={el?._id}>
                            <div className="promotions-grid__item-img">
                              <LazyLoadImage
                                  src={el?.imageUrl}
                                  width={234}
                                  height={235}
                                  alt="Акция"
                                  effect="opacity"
                                  onClick={() => openModal(el)}
                              />
                            </div>
                          </div>
                      ))}
                    </div>
                ) : (
                    <h4 className="promotions-title">Акций нет</h4>
                )
            )
          }
        </div>
      </div>
      <ModalPromotions show={modalShow} onHide={() => setModalShow(false)} item={modalActiveItem} />
    </>
  );
};

export default Promotions;
