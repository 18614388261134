import React from 'react';
import TitleSection from "../@components/titleSection";

const TermsOfUse = () => {
    return (
        <div className="container" style={{minHeight: '300px', paddingTop: '50px'}}>
            <TitleSection redLine={false}>Пользовательское соглашение</TitleSection>
        </div>
    );
};

export default TermsOfUse;