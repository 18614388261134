import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import products from '../example/products';
import popularProducts from '../example/popularProducts';
import productsOnSale from '../example/productsOnSale';

const initialState = {
  products: products,
  popularProducts: [],
  popularProductsLoading: null,
  productsOnSale: [],
  productsOnSaleLoading: null,
};

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const fetchPopularProducts = createAsyncThunk(
  'products/fetchPopularProducts',
  async function (_, { rejectWithValue }) {
    try {
      // const response = await fetch('https://jsonplaceholder.typicode.com/todos?_limit=10')

      // if (!response.ok) {
      //     throw new Error('Server Error!')
      // }s
      // const data = await response.json()
      // return data
      await timeout(1000);
      return popularProducts;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchProductsOnSale = createAsyncThunk(
  'products/fetchProductsOnSale',
  async function (_, { rejectWithValue }) {
    try {
      // const response = await fetch('https://jsonplaceholder.typicode.com/posts?_limit=10')
      //
      // if (!response.ok) {
      //     throw new Error('Server Error!')
      // }
      //
      // const data = await response.json()
      // return data
      await timeout(1000);
      return productsOnSale;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPopularProducts.pending]: (state) => {
      state.popularProductsLoading = 'pending';
    },
    [fetchPopularProducts.fulfilled]: (state, action) => {
      state.popularProductsLoading = 'success';
      state.popularProducts = action.payload;
    },
    [fetchPopularProducts.rejected]: (state) => {
      state.popularProductsLoading = 'error';
    },
    [fetchProductsOnSale.pending]: (state) => {
      state.productsOnSaleLoading = 'pending';
    },
    [fetchProductsOnSale.fulfilled]: (state, action) => {
      state.productsOnSaleLoading = 'success';
      state.productsOnSale = action.payload;
    },
    [fetchProductsOnSale.rejected]: (state) => {
      state.productsOnSaleLoading = 'error';
    },
  },
});

export const {} = productsSlice.actions;

export default productsSlice.reducer;
