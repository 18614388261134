import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isModalConfirmOpen: false,
    selectedItem: null,
    selectedItemType: null,
};

const modalsSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        toggleConfirmModal:(state, action) => {
            state.isModalConfirmOpen = action.payload.status
            state.selectedItem = action.payload.selectedItem
            state.selectedItemType = action.payload.itemType
        },
    },
});

export const { toggleConfirmModal } = modalsSlice.actions;

export default modalsSlice.reducer;
