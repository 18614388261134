import React from 'react';
import { Link } from 'react-router-dom';
import TitleSection from 'pages/@components/titleSection';
import {
  CategoriesKanzler,
  CategoriesBurger,
  CategoriesLetter,
  CategoriesPen,
  CategoriesScissors,
  CategoriesSale,
  CategoriesGift,
  CategoriesToys,
  CategoriesOffice,
  CategoriesArt,
  CategoriesDraw,
  CategoriesPoly,
} from 'assets';

import './categories.scss';
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

const Categories = () => {
  return (
    <div className='categories'>
      <div className='container'>
        <TitleSection>Категории</TitleSection>
        <div className='categories__grid row'>
          <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
            <Link to='/catalog/kanzler' className='categories__item'>
              <div className="categories__item-img">
                <LazyLoadImage
                    src={CategoriesKanzler}
                    width={187}
                    height={86}
                    alt="Kanzler"
                    effect="opacity"
                />
              </div>
              <p>Kanzler</p>
            </Link>
          </div>
          <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
            <Link to='/catalog/burger' className='categories__item'>
              <div className="categories__item-img">
                <LazyLoadImage
                    src={CategoriesBurger}
                    width={109}
                    height={89}
                    alt="Bürger"
                    effect="opacity"
                />
              </div>
              <p>Bürger</p>
            </Link>
          </div>
          <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
            <Link to='/catalog/graphics' className='categories__item'>
              <div className="categories__item-img">
                <LazyLoadImage
                    src={CategoriesLetter}
                    width={109}
                    height={87}
                    alt="Письмо и графика"
                    effect="opacity"
                />
              </div>
              <p>Письмо и графика</p>
            </Link>
          </div>
          <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
            <Link to='/catalog/graphics' className='categories__item'>
              <div className="categories__item-img">
                <LazyLoadImage
                    src={CategoriesPen}
                    width={109}
                    height={87}
                    alt="Письмо и графика"
                    effect="opacity"
                />
              </div>
              <p>Письмо и графика</p>
            </Link>
          </div>
          <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
            <Link to='/catalog/office-supplies' className='categories__item'>
              <div className="categories__item-img">
                <LazyLoadImage
                    src={CategoriesScissors}
                    width={109}
                    height={89}
                    alt="Канц товары"
                    effect="opacity"
                />
              </div>
              <p>Канц товары</p>
            </Link>
          </div>
          <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
            <Link to='/catalog/arts-supplies' className='categories__item'>
              <div className="categories__item-img">
                <LazyLoadImage
                    src={CategoriesDraw}
                    width={109}
                    height={88}
                    alt="Художественные товары"
                    effect="opacity"
                />
              </div>
              <p>Художественные товары</p>
            </Link>
          </div>
          <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
            <Link to='/catalog/arts' className='categories__item'>
              <div className="categories__item-img">
                <LazyLoadImage
                    src={CategoriesArt}
                    width={111}
                    height={93}
                    alt="Творчество"
                    effect="opacity"
                />
              </div>
              <p>Творчество</p>
            </Link>
          </div>
          <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
            <Link to='/catalog/poly-materials' className='categories__item'>
              <div className="categories__item-img">
                <LazyLoadImage
                    src={CategoriesPoly}
                    width={109}
                    height={90}
                    alt="Полиграфические материалы"
                    effect="opacity"
                />
              </div>
              <p>Полиграфические материалы</p>
            </Link>
          </div>
          <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
            <Link to='/catalog/office-technic' className='categories__item'>
              <div className="categories__item-img">
                <LazyLoadImage
                    src={CategoriesOffice}
                    width={108}
                    height={90}
                    alt="Офисное  оборудование"
                    effect="opacity"
                />
              </div>
              <p>Офисное оборудование</p>
            </Link>
          </div>
          <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
            <Link to='/catalog/games-and-toys' className='categories__item'>
              <div className="categories__item-img">
                <LazyLoadImage
                    src={CategoriesToys}
                    width={108}
                    height={90}
                    alt="Игры и игрушки"
                    effect="opacity"
                />
              </div>
              <p>Игры и игрушки</p>
            </Link>
          </div>
          <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
            <Link to='/catalog/souvenirs' className='categories__item'>
              <div className="categories__item-img">
                <LazyLoadImage
                    src={CategoriesGift}
                    width={108}
                    height={90}
                    alt="Сувенирная продукция"
                    effect="opacity"
                />
              </div>
              <p>Сувенирная продукция</p>
            </Link>
          </div>
          <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
            <Link to='/catalog/sales' className='categories__item'>
              <div className="categories__item-img">
                <LazyLoadImage
                    src={CategoriesSale}
                    width={110}
                    height={89}
                    alt="Распродажи"
                    effect="opacity"
                />
              </div>
              <p>Распродажи</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
