import React from 'react';
import {useSelector} from "react-redux";
import './about.scss';
import {
  AboutClients,
  AboutShip,
  MarkIcon,
  PhoneIcon,
  PhoneIconMobile,
  SocialsFaceBookIconBlack,
  SocialsInstagramIconBlack, SocialsTiktokIconBlack,
  SocialsWhatsAppIconBlack,
} from 'assets';
import { providers } from 'assets/images/providers';
import TitleSection from '../@components/titleSection';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';
import {formatNumber} from "../../helpers/formatNumber";

const About = () => {
  const {website} = useSelector((state) => state.website)

  return (
    <div className='about'>
      <div className='about-main about-section'>
        <div className='container'>
          <div className='about-main__row about-row row'>
            <div className='col-md-6'>
              <div className="about-row__img about-row__img--big">
                <LazyLoadImage
                    src={website?.aboutPage?.block1ImageUrl}
                    width={420}
                    height={456}
                    alt="Фото офиса"
                    effect="opacity"
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-main__content'>
                {
                  website?.aboutPage?.block1Title && (
                      <TitleSection redLineShort={true}>{website?.aboutPage?.block1Title}</TitleSection>
                  )
                }

                <p className='about-text' dangerouslySetInnerHTML={{__html: website?.aboutPage?.block1Body}}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='about-banner'>
        {
          website?.aboutPage?.block2Title && (
              <TitleSection redLine={false}><span className="text-white m-auto">{website?.aboutPage?.block2Title}</span></TitleSection>
          )
        }
        <p className='about-text' dangerouslySetInnerHTML={{__html: website?.aboutPage?.block2Body}}></p>
      </div>
      <div className='about-locations about-section'>
        <div className='container'>
          <div className='about-locations__row about-row row'>
            <div className='col-lg-6'>
              <div className='about-locations__left'>
                {
                    website?.aboutPage?.block3Title && (
                        <TitleSection redLineShort={true}>{website?.aboutPage?.block3Title}</TitleSection>
                    )
                }
                <p className='about-text' dangerouslySetInnerHTML={{__html: website?.aboutPage?.block3Body}}></p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='about-locations__right row'>
                {
                    website?.branches.length > 0 && website?.branches.map(el => (
                        <div className='col-sm-4' key={el?._id}>
                          <div className='about-locations__card'>
                            <MarkIcon />
                            <h5 className='about-locations__card-title'>{el?.address}</h5>
                            <a className='about-text' href={`tel:+996${formatNumber(el?.mobilePhone)}`}>
                              <PhoneIconMobile />
                              {el?.mobilePhone}
                            </a>
                            <a className='about-text' href={`tel:+996${formatNumber(el?.homePhone)}`}>
                              <PhoneIcon />
                              {el?.homePhone}
                            </a>
                          </div>
                        </div>
                    ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='about-stock about-section'>
        <div className='container'>
          <div className='about-stock__row about-row row'>
            <div className='col-md-6'>
              <div className='about-stock__left'>
                {
                    website?.aboutPage?.block4Title && (
                        <TitleSection redLineShort={true}>{website?.aboutPage?.block4Title}</TitleSection>
                    )
                }
                <p className='about-text' dangerouslySetInnerHTML={{__html: website?.aboutPage?.block4Body}}></p>
                <div className='about-stock__socials'>
                  <a href={`https://wa.me/${website?.whatsApp}`} target='_blank'>
                    <SocialsWhatsAppIconBlack />
                    What’s App
                  </a>
                  <a href={website?.instagram} target='_blank'>
                    <SocialsInstagramIconBlack />
                    Instagram
                  </a>
                  <a href={website?.facebook} target='_blank'>
                    <SocialsFaceBookIconBlack />
                    Facebook
                  </a>
                  <a href={website?.tiktok} target='_blank'>
                    <SocialsTiktokIconBlack />
                    Tiktok
                  </a>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="about-row__img about-row__img--big">
                <LazyLoadImage
                    src={website?.aboutPage?.block4ImageUrl}
                    width={420}
                    height={456}
                    alt="Фото Ассортимента"
                    effect="opacity"
                />
              </div>
            </div>
          </div>
          <div className='about-stock__bg'></div>
        </div>
      </div>
      <div className='about-clients about-section'>
        <div className='container'>
          <div className='about-clients__row about-row row'>
            <div className='col-md-6'>
              <div className="about-row__img about-row__img--small">
                <LazyLoadImage
                    src={AboutClients}
                    width={442}
                    height={279}
                    alt="Фото"
                    effect="opacity"
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-clients__right'>
                {
                    website?.aboutPage?.block5Title && (
                        <TitleSection redLineShort={true}>{website?.aboutPage?.block5Title}</TitleSection>
                    )
                }
                <p className='about-text' dangerouslySetInnerHTML={{__html: website?.aboutPage?.block5Body}}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='about-ship about-section'>
        <div className='container'>
          <div className='about-ship__row about-row row'>
            <div className='col-md-6'>
              <div className='about-ship__left'>
                {
                    website?.aboutPage?.block6Title && (
                        <TitleSection redLineShort={true}>{website?.aboutPage?.block6Title}</TitleSection>
                    )
                }
                <p className='about-text' dangerouslySetInnerHTML={{__html: website?.aboutPage?.block6Body}}></p>
                <a href={`tel:${website?.mainNumber}`} className="about-ship__btn">
                  Позвонить
                  <PhoneIcon/>
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="about-row__img about-row__img--small">
                <LazyLoadImage
                    src={AboutShip}
                    width={348}
                    height={217}
                    alt="Фото"
                    effect="opacity"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='about-providers about-section'>
        <div className='container'>
          <div className='about-providers__content'>
            {
                website?.aboutPage?.block7Title && (
                    <TitleSection redLine={false}>{website?.aboutPage?.block7Title}</TitleSection>
                )
            }
            <p className='about-text' dangerouslySetInnerHTML={{__html: website?.aboutPage?.block7Body}}></p>
          </div>
          <div className='about-providers__grid'>
            {providers.map((el, ind) => (
                <LazyLoadImage
                    key={ind}
                    src={el.src}
                    width={el.width}
                    height={el.height}
                    alt="Поставщик"
                    effect="opacity"
                />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
