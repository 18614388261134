import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {resendActivationLink, closeModalAuth, sendPasswordRecoveryLink} from "store/slices/authSlice";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Loader from "../../@components/loader";
import {CrossIcon} from "assets";
import './modalAuth.scss'


const ModalAuth = () => {
    const {modalAuthShow, modalAuthLoading} = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const onHideModal = () => {
        dispatch(closeModalAuth())
    }

    const handleResendActivationLink = () => {
        if (modalAuthLoading !== 'loading') {
            dispatch(resendActivationLink())
        }
    }

    const [email, setEmail] = useState('')
    const handleRecoverPassword = (e) => {
        e.preventDefault()
        if (modalAuthLoading !== 'loading') {
            dispatch(sendPasswordRecoveryLink({email}))
        }
    }


    return (
        <Modal
            show={modalAuthShow.status}
            onHide={onHideModal}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Body className='auth-modal__body'>
                {
                    modalAuthShow.type === 'signup' ? (
                        <>
                            <h4 className="auth-modal__title">Вы успешно зарегистрировались!</h4>
                            <p className="auth-modal__text">Письмо с активацией было отправлено на ваш E-mail. <br/> Проверьте папки: входящие, спам, промоакции.</p>
                            <div className="auth-modal__btn-wrap">
                                <Link to="login" className="auth-modal__btn" onClick={onHideModal}>Войти</Link>
                            </div>
                        </>
                    ) : modalAuthShow.type === 'login' ? (
                        <>
                            {modalAuthLoading === 'loading' && <Loader fullScreen={true}/>}
                            <h4 className="auth-modal__title">Ваш аккаунт НЕ активирован!</h4>
                            <p className="auth-modal__text">Письмо с активацией было отправлено на ваш E-mail. <br/> Проверьте папки: входящие, спам, промоакции.</p>
                            <div className="auth-modal__btn-wrap">
                                <button type="button" className="auth-modal__btn" onClick={handleResendActivationLink}>Переотправить письмо</button>
                            </div>
                        </>
                    ) : modalAuthShow.type === 'recovery' && (
                        <form onSubmit={handleRecoverPassword}>
                            {modalAuthLoading === 'loading' && <Loader fullScreen={true}/>}
                            <h4 className="auth-modal__title">Восстановление пароля</h4>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Введите ваш e-mail"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required={true}
                                />
                            </Form.Group>
                            <div className="auth-modal__btn-wrap">
                                <button type="submit" className="auth-modal__btn">Восстановить пароль</button>
                            </div>
                        </form>
                    )
                }
            <button type='button' className='auth-modal__btn-close' onClick={onHideModal}>
                <CrossIcon />
            </button>
            </Modal.Body>
        </Modal>
    );
};

export default ModalAuth;