import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {editUser} from "store/slices/authSlice";
import {toast} from "react-toastify";
import { DefaultProfileImage, PencilIcon } from 'assets';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Loader from "pages/@components/loader";



const ProfileEdit = ({ toggleEditProfile, user }) => {
  const [name, setName] = useState(user?.name)
  const [surname, setSurname] = useState(user?.surname)
  const [phone, setPhone] = useState(user?.phone)
  const [email, setEmail] = useState(user?.email)

  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])
  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }
  const notify = (text) => toast(text);
  const validateSelectedFile = () => {
    if (selectedFile) {
      const MAX_FILE_SIZE = 10240 // 10MB
      const fileSizeKiloBytes = selectedFile.size / 1024
      if(fileSizeKiloBytes > MAX_FILE_SIZE){
        notify('Фото должно быть меньше 10мб')
        return false
      }
    }
    return true
  };

  const dispatch = useDispatch()
  const {editUserLoading} = useSelector((state) => state.auth)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validateSelectedFile()) {
      if (editUserLoading !== 'loading') {
        dispatch(editUser({name, surname, phone, email, id: user.id, toggleEditProfile, image: selectedFile, token: user?.token}))
      }
    }
  }

  return (
    <div className='profile-edit'>
      <div className='profile-edit__top'>
        <div className='profile-edit__top-img'>
          <img src={preview ? preview : user?.imageUrl ? user?.imageUrl : DefaultProfileImage } alt='Аватар' />
        </div>
        <div className='profile-edit__top-content'>
          <p className='profile-edit__top-name'>{user?.name} {user?.surname}</p>

          <label
            className='profile-btn profile-btn--photo btn-transitioned-animated'
          >
            Изменить фото <PencilIcon />
            <input type='file' accept="image/png, image/webp, image/jpeg" onChange={onSelectFile} />
          </label>
        </div>
      </div>
      <Form className='profile-edit__form' onSubmit={handleSubmit}>
        <Row>
          <Form.Group as={Col} sm={6} controlId='formGridName'>
            <Form.Label className='profile-edit__form-label'>Имя</Form.Label>
            <Form.Control
              className='profile-edit__form-input'
              type='text'
              placeholder='Введите имя'
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group as={Col} sm={6} controlId='formGridSurname'>
            <Form.Label className='profile-edit__form-label'>Фамилия</Form.Label>
            <Form.Control
              className='profile-edit__form-input'
              type='text'
              placeholder='Введите фамилию'
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group as={Col} sm={6} controlId='formGridPhone'>
            <Form.Label className='profile-edit__form-label'>Телефон</Form.Label>
            <Form.Control
              className='profile-edit__form-input'
              type='number'
              placeholder='Введите номер'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group as={Col} sm={6} controlId='formGridEmail'>
            <Form.Label className='profile-edit__form-label'>E-mail</Form.Label>
            <Form.Control
              className='profile-edit__form-input'
              type='email'
              placeholder='Введите e-mail'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
        </Row>
        <div className='profile-edit__form-bottom'>
          <button type='submit' className='profile-edit__btn profile-edit__btn--save'>
            Сохранить изменения
          </button>
          <button
            type='button'
            className='profile-edit__btn profile-edit__btn--cancel btn-transitioned-animated'
            onClick={toggleEditProfile}
          >
            Отменить
          </button>
        </div>
      </Form>
      {
        editUserLoading === 'loading' && <Loader fullScreen={true}/>
      }
    </div>
  );
};

export default ProfileEdit;
