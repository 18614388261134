import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './slices/cartSlice';
import productsReducer from './slices/productsSlice';
import searchReducer from './slices/searchSlice';
import authReducer from './slices/authSlice';
import newsSlice from "./slices/newsSlice";
import salesSlice from "./slices/salesSlice";
import promocodesSlice from "./slices/promocodesSlice";
import modalsSlice from "./slices/modalsSlice";
import websiteSlice from "./slices/websiteSlice";
import ordersSlice from "./slices/ordersSlice"

export default configureStore({
  reducer: {
    products: productsReducer,
    cart: cartReducer,
    search: searchReducer,
    auth: authReducer,
    news: newsSlice,
    sales: salesSlice,
    promocodes: promocodesSlice,
    modals: modalsSlice,
    website: websiteSlice,
    orders: ordersSlice
  },
});
