import React, {useEffect} from 'react';
import {Navigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {activateAccount} from "store/slices/authSlice";
import Loader from "../../@components/loader";
import '../auth.scss'

const AccountActivation = () => {
    const [queryParameters] = useSearchParams()
    const dispatch = useDispatch()

    useEffect(() => {
        if (queryParameters.get("token")) {
            dispatch(activateAccount({token: queryParameters.get("token")}))
        }
    },[])

    const {activationLoading} = useSelector((state) => state.auth)

    return (
        <div className="container auth-account-activation">
            {
                activationLoading === 'loading' ? (
                    <Loader withHeight={300}/>
                ) : (
                    <>
                        {
                            activationLoading === 'success' ? <Navigate to={'/login'}/> : (
                                <h3 className="auth-account-activation__title">Ссылка просрочена. Попробуйте ещё раз.</h3>
                            )
                        }
                    </>
                )
            }
        </div>
    );
};

export default AccountActivation;