import React from 'react';
import './customCounter.scss';

const CustomCounter = ({ setProductCounter, productCounter }) => {
  const increment = () => setProductCounter(productCounter + 1);
  const decrement = () => productCounter > 0 && setProductCounter(productCounter - 1);

  return (
    <div className='custom-counter'>
      <button type='button' onClick={decrement}>
        -
      </button>
      <span>{productCounter}</span>
      <button type='button' onClick={increment}>
        +
      </button>
    </div>
  );
};

export default CustomCounter;
