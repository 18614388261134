import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {cancelChangeEmail} from "store/slices/authSlice";
import { DefaultProfileImage, PencilIcon } from 'assets';
import Loader from "../../../@components/loader";
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';


const ProfileInfo = ({ toggleEditProfile, user }) => {
  const dispatch = useDispatch()
  const {cancelChangeEmailLoading} = useSelector((state) => state.auth)

  const handleCancelChangeEmail = () => {
    if (cancelChangeEmailLoading !== 'loading') {
      dispatch(cancelChangeEmail({token: user?.token, id: user?.id}))
    }
  }

  return (
    <div className='profile-info'>
      <div className='profile-info__img'>
        <LazyLoadImage
            src={user?.imageUrl ? user?.imageUrl : DefaultProfileImage}
            width={260}
            height={260}
            alt="Аватар"
            effect="opacity"
        />
      </div>
      <div className='profile-info__content'>
        <div className='profile-info__item'>
          <p className='profile-info__item-title'>Имя</p>
          <p className='profile-info__item-text'>{user?.name}</p>
        </div>
        <div className='profile-info__item'>
          <p className='profile-info__item-title'>Фамилия</p>
          <p className='profile-info__item-text'>{user?.surname}</p>
        </div>
        <div className='profile-info__item'>
          <p className='profile-info__item-title'>Телефон</p>
          <p className='profile-info__item-text'>{user?.phone}</p>
        </div>
        <div className='profile-info__item'>
          <p className='profile-info__item-title'>E-mail</p>
          <p className='profile-info__item-text'>{user?.email}</p>
          {
            user?.changeEmailTo && (
                <>
                  <p className="profile-info__item-text--small">Письмо для смены почты было отправлено на <span>{user?.changeEmailTo}</span></p>
                  <p className="profile-info__item-text--small">Для отмены изменения почты нажмите - <button type="button" onClick={handleCancelChangeEmail}>отмена</button></p>
                </>
              )
          }
        </div>
        <button
          type='button'
          className='profile-btn btn-transitioned-animated'
          onClick={toggleEditProfile}
        >
          Редактировать данные <PencilIcon />
        </button>
      </div>
      {
        cancelChangeEmailLoading === 'loading' && <Loader fullScreen={true}/>
      }
    </div>
  );
};

export default ProfileInfo;
