import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import {useSelector} from "react-redux";
import {
  PhoneIcon,
  PhoneIconMobile,
  SocialsFaceBookIcon,
  SocialsInstagramIcon,
  SocialsWhatsAppIcon,
  Logo,
  MooreLogo, SocialsTiktokIcon,
} from 'assets';

import './footer.scss';
import {formatNumber} from "../../../helpers/formatNumber";

const Footer = () => {
  const {website} = useSelector((state) => state.website)

  return (
    <footer className='footer'>
      <div className='container'>
        <Link className='footer-title' to='/'>
          Kanzler&Bürger
        </Link>
        <div className='footer-middle'>
          <div className='row'>
            <div className='col-md-3 col-6'>
              <div className='footer-column'>
                <h6 className='footer-column__title'>Меню</h6>
                <NavLink to='/catalog' className='footer-column__link'>
                  Каталог
                </NavLink>
                <NavLink to='promotions' className='footer-column__link'>
                  Акции
                </NavLink>
                <NavLink to='catalog/popular-products' className='footer-column__link'>
                  Популярные товары
                </NavLink>
                <NavLink to='catalog/new' className='footer-column__link'>
                  Новинки
                </NavLink>
                <NavLink to='/news' className='footer-column__link'>
                  Новости
                </NavLink>
                <NavLink to='/about' className='footer-column__link'>
                  О компании
                </NavLink>
                <NavLink to='contacts' className='footer-column__link'>
                  Контакты
                </NavLink>
                <Link className='footer-logo' to='/'>
                  <img src={Logo} alt='Логотип' />
                </Link>
              </div>
            </div>
            <div className='col-md-3 col-6'>
              <div className='footer-column'>
                <h6 className='footer-column__title'>Категории</h6>
                <Link to='/catalog/paper' className='footer-column__link'>
                  Бумага
                </Link>
                <Link to='/catalog/graphics' className='footer-column__link'>
                  Письмо и графика
                </Link>
                <Link to='catalog/office-supplies' className='footer-column__link'>
                  Канц товары
                </Link>
                <Link to='catalog/art-supplies' className='footer-column__link'>
                  Художественные товары
                </Link>
                <Link to='catalog/arts' className='footer-column__link'>
                  Творчество
                </Link>
                <Link to='catalog/poly-materials' className='footer-column__link'>
                  Полиграфические материалы
                </Link>
                <Link to='catalog/sales' className='footer-column__link'>
                  Распродажи
                </Link>
                <Link to='catalog/office-technic' className='footer-column__link'>
                  Офисное оборудование
                </Link>
                <Link to='catalog/games-and-toys' className='footer-column__link'>
                  Игры и игрушки
                </Link>
                <Link to='catalog/souvenirs' className='footer-column__link'>
                  Сувенирная продукция
                </Link>
              </div>
            </div>
            <div className='col-md-3 col-6'>
              <div className='footer-column footer-column--contacts'>
                <Link className='footer-column__title' to="contacts">Контакты</Link>
                {
                    website?.branches.length > 0 && website?.branches.map(el => (
                        <div key={el?._id}>
                          <p className='footer-column__subtitle'>{el?.address}</p>
                          <a href={`tel:+996${formatNumber(el?.mobilePhone)}`} className='footer-column__link'>
                            <PhoneIconMobile />
                            {el?.mobilePhone}
                          </a>
                          <a href={`tel:+996${formatNumber(el?.homePhone)}`} className='footer-column__link'>
                            <PhoneIcon />
                            {el?.homePhone}
                          </a>
                        </div>
                    ))
                }
              </div>
            </div>
            <div className='col-md-3 col-6'>
              <div className='footer-column footer-column--socials'>
                <div>
                  <h6 className='footer-column__title'>Мы в соц сетях</h6>
                  <a
                      href={website?.instagram}
                    target='_blank'
                    className='footer-column__link'
                  >
                    <SocialsInstagramIcon />
                    Instagram
                  </a>
                  <a
                    href={website?.facebook}
                    target='_blank'
                    className='footer-column__link'
                  >
                    <SocialsFaceBookIcon />
                    Facebook
                  </a>
                  <a
                    href={`https://wa.me/${website?.whatsApp}`}
                    target='_blank'
                    className='footer-column__link'
                  >
                    <SocialsWhatsAppIcon />
                    What’s App
                  </a>
                  <a
                    href={website?.tiktok}
                    target='_blank'
                    className='footer-column__link'
                  >
                    <SocialsTiktokIcon />
                    Tiktok
                  </a>
                </div>
                <a href='https://to-moore.com/' target='_blank' className='moore-logo'>
                  <img src={MooreLogo} alt='Moore Studio' />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <p>Kanzler & Burger (c) 2017. All rights reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
