import React, {useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getOneNews} from "../../store/slices/newsSlice";
import Breadcrumbs from '../@components/breadcrumbs';
import { ArrowIconLong, ArrowIconPrev } from 'assets';
import NewsCard from '../@components/newsCard';
import {isoDateToNormal} from "helpers/isoDateToNormal";
import './newsOnePage.scss';
import Loader from "../@components/loader";
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';


const NewsOnePage = () => {
  const location = useLocation()
  const id = location.pathname.slice(6)
  const dispatch = useDispatch()
  const {oneNews, getOneNewsLoading} = useSelector((state) => state.news)

  useEffect(() => {
    dispatch(getOneNews({id}))
  },[location])

  return (
    <div className='newsPage'>
      <div className='container'>
        <Breadcrumbs />
        {
          ((getOneNewsLoading === 'success' || getOneNewsLoading === 'error') && !oneNews) ? (
              <h4 className="news-title">Новость не найдена</h4>
          ) : (
              getOneNewsLoading === 'loading' ? <Loader withHeight={'300'}/> : oneNews && (
                  <>
                    <div className='newsPage-wrap'>
                      <div className='newsPage-left'>
                        <div className="newsPage-left__img">
                          <LazyLoadImage
                              src={oneNews?.imageUrl}
                              width={320}
                              height={320}
                              alt="Фото новостей"
                              effect="opacity"
                          />
                        </div>
                        <div className='newsPage-left__links'>
                          <Link className={`newsPage-left__links--prev ${!oneNews?.prevPost && 'newsPage-left__links--disabled'}`} to={`/news/${oneNews?.prevPost || ''}`}>
                            <ArrowIconPrev /> Предыдущая
                          </Link>
                          <Link className={`newsPage-left__links--next ${!oneNews?.nextPost && 'newsPage-left__links--disabled'}`} to={`/news/${oneNews?.nextPost || ''}`}>
                            Следующая <ArrowIconPrev />
                          </Link>
                        </div>
                      </div>
                      <div className='newsPage-content'>
                        <h1 className='newsPage-title'>
                          {oneNews?.title}
                        </h1>
                        <p className='newsPage-date'>{isoDateToNormal(oneNews?.createdAt)}</p>

                        <div className="newsPage-body" dangerouslySetInnerHTML={{__html: oneNews?.body}}></div>
                      </div>
                    </div>
                    <div className='newsPage-recommend'>
                      {
                        oneNews?.newestPosts?.length > 0 && (
                              <div className='news-grid'>
                                {oneNews?.newestPosts.map(el => (
                                    <NewsCard key={el?._id} item={el} iconCircle={true} />
                                ))}
                              </div>
                          )
                      }
                      <Link to='/news' className='newsPage-recommend__link'>
                        Все новости <ArrowIconLong />
                      </Link>
                    </div>
                  </>
              )
          )
        }
      </div>
    </div>
  );
};

export default NewsOnePage;
