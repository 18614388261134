import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAllNews, getSortedNews, removeLoading} from "../../store/slices/newsSlice";
import Breadcrumbs from '../@components/breadcrumbs';
import NewsCard from '../@components/newsCard';
import CustomSelect from '../@components/customSelect';
import './news.scss';
import Loader from "../@components/loader";


const News = () => {
    const {sortedNews, getAllNewsLoading} = useSelector((state) => state.news)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllNews())
    },[])

    const [customSelect, setCustomSelect] = useState('')

    useEffect(() => {
        if (sortedNews.length > 0) {
            dispatch(getSortedNews(customSelect))
            setTimeout(() => {
                dispatch(removeLoading())
            },300)
        }
    },[customSelect])

    return (
        <div className='news'>
            <div className='container'>
                <Breadcrumbs />
                <CustomSelect page="news" setCustomSelect={setCustomSelect}/>
                {
                    getAllNewsLoading === 'loading' ? <Loader withHeight="300"/> : (
                        sortedNews.length > 0 ? (
                            <div className='news-grid'>
                                {
                                    sortedNews.map(el => (
                                        <NewsCard key={el._id} item={el} iconCircle={true} />
                                    ))
                                }
                            </div>
                        ) : (
                            <h4 className="news-title">Новостей нет</h4>
                        )
                    )
                }
            </div>
        </div>
    );
};

export default News;
