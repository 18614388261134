import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosClient} from "../../axios/axiosClient";
import {toast} from "react-toastify";
import axios from "axios";
const notify = (text) => toast(text);
const website = JSON.parse(localStorage.getItem("website"));

const initialState = {
    website: website ? website : null,
    websiteLoading: null,
    isChangedToken: null,
    branchesLoading: null,
    editAboutPageLoading: null,
};

export const websiteGetIsChangedToken = createAsyncThunk(
    'website/websiteGetIsChangedToken',
    async function(_, { rejectWithValue }) {
        try {
            const response = await axiosClient.get(`/website/get-is-changed-token`)
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    }
);


export const websiteInit = createAsyncThunk(
    'website/websiteInit',
    async function(_, { rejectWithValue }) {
        try {
            const response = await axiosClient.get(`/website/init`)
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    }
);

export const websiteEdit = createAsyncThunk(
    'website/websiteEdit',
    async function({instagram, facebook, whatsApp, tiktok, mainNumber, mainEmail, token}, { rejectWithValue }) {
        try {
            const response = await axiosClient.put(`/website/edit`, {instagram, facebook, whatsApp, tiktok, mainNumber, mainEmail}, {headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    }
);


export const createBranches = createAsyncThunk(
    'website/createBranches',
    async function ({address, homePhone, mobilePhone, token, lat, lng}, { rejectWithValue  }) {
        const branch = {
            address,
            homePhone,
            mobilePhone,
            lat,
            lng
        }
        try {
            const response = await axiosClient.put(`/website/create-branch`, {branch}, {headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);
export const updateBranches = createAsyncThunk(
    'website/updateBranches',
    async function ({id, address, homePhone, mobilePhone, token, lat, lng}, { rejectWithValue  }) {
        try {
            const response = await axiosClient.put(`/website/edit-branch`,{ address, homePhone, mobilePhone, id, lat, lng},{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);
export const deleteBranches = createAsyncThunk(
    'website/deleteBranches',
    async function ({id, token}, { rejectWithValue  }) {
        try {
            const response = await axiosClient.delete(`/website/delete-branch/${id}`,{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);

export const editAboutPage = createAsyncThunk(
    'website/editAboutPage',
    async function ({token, aboutData}, { rejectWithValue  }) {
        try {
            try {
                if (aboutData?.block1Image) {
                    const cloudinaryConfigResponse = await axiosClient.post('/website/generate-about-page-photo-signature', {imagePublicId: aboutData?.block1ImagePublicId}, {headers: { 'Authorization': `Bearer ${token}` }})
                    const cloudinaryConfig = cloudinaryConfigResponse.data
                    const formData = new FormData();
                    formData.append("file", aboutData?.block1Image);
                    formData.append("upload_preset", cloudinaryConfig.uploadPreset);
                    formData.append("eager", cloudinaryConfig.eager);
                    formData.append("api_key", cloudinaryConfig.apiKey);
                    formData.append("timestamp", cloudinaryConfig.timestamp);
                    formData.append("signature", cloudinaryConfig.signature);
                    if (aboutData?.block1ImagePublicId) {
                        formData.append("public_id", aboutData?.block1ImagePublicId);
                    }
                    const dataRes = await axios.post(
                        `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloudName}/image/upload`,
                        formData
                    );
                    if (dataRes.data.eager[0].url) {
                        aboutData.block1ImageUrl = dataRes.data.eager[0].url
                    } else {
                        aboutData.block1ImageUrl = dataRes.data.url
                    }
                    aboutData.block1ImagePublicId = /[^/]*$/.exec(dataRes.data.public_id)[0]
                }
            } catch (err) {
                return rejectWithValue('Ошибка загрузки изображения');
            }
            try {
                if (aboutData?.block4Image) {
                    const cloudinaryConfigResponse = await axiosClient.post('/website/generate-about-page-photo-signature', {imagePublicId: aboutData?.block4ImagePublicId}, {headers: { 'Authorization': `Bearer ${token}` }})
                    const cloudinaryConfig = cloudinaryConfigResponse.data
                    const formData = new FormData();
                    formData.append("file", aboutData?.block4Image);
                    formData.append("upload_preset", cloudinaryConfig.uploadPreset);
                    formData.append("eager", cloudinaryConfig.eager);
                    formData.append("api_key", cloudinaryConfig.apiKey);
                    formData.append("timestamp", cloudinaryConfig.timestamp);
                    formData.append("signature", cloudinaryConfig.signature);
                    if (aboutData?.block4ImagePublicId) {
                        formData.append("public_id", aboutData?.block4ImagePublicId);
                    }
                    const dataRes = await axios.post(
                        `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloudName}/image/upload`,
                        formData
                    );
                    if (dataRes.data.eager[0].url) {
                        aboutData.block4ImageUrl = dataRes.data.eager[0].url
                    } else {
                        aboutData.block4ImageUrl = dataRes.data.url
                    }
                    aboutData.block4ImagePublicId = /[^/]*$/.exec(dataRes.data.public_id)[0]
                }
            } catch (err) {
                return rejectWithValue('Ошибка загрузки изображения');
            }


            const response = await axiosClient.put('/website/edit-about-page', {aboutData},{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);


const websiteSlice = createSlice({
    name: 'website',
    initialState,
    reducers: {},
    extraReducers: {
        [websiteInit.pending]: (state) => {
            state.websiteLoading = 'loading';
        },
        [websiteInit.fulfilled]: (state, action) => {
            state.websiteLoading = 'success';
            state.website = action.payload
            localStorage.setItem('website', JSON.stringify(action.payload))
        },
        [websiteInit.rejected]: (state) => {
            state.websiteLoading = 'error';
        },
        [websiteEdit.pending]: (state) => {
            state.websiteLoading = 'loading';
        },
        [websiteEdit.fulfilled]: (state, action) => {
            state.websiteLoading = 'success';
            state.website = {...state.website, ...action.payload}
            localStorage.setItem('website', JSON.stringify({...state.website, ...action.payload}))
            notify('Данные успешно сохранены!')
            console.log({...state.website, ...action.payload})
        },
        [websiteEdit.rejected]: (state, action) => {
            state.websiteLoading = 'error';
            notify(action.payload)
        },
        [websiteGetIsChangedToken.pending]: (state) => {
        },
        [websiteGetIsChangedToken.fulfilled]: (state, action) => {
            state.isChangedToken = action?.payload?.isChangedToken
        },
        [websiteGetIsChangedToken.rejected]: (state, action) => {
            notify(action.payload)
        },

        [createBranches.pending]: (state) => {
            state.branchesLoading = 'loading';
        },
        [createBranches.fulfilled]: (state, action) => {
            state.branchesLoading = 'success'
            state.website = action.payload
            localStorage.setItem('website', JSON.stringify(action.payload))
            notify('Филиал создан!')
        },
        [createBranches.rejected]: (state, action) => {
            state.branchesLoading = 'error'
            notify(action.payload)
        },

        [updateBranches.pending]: (state) => {
            state.branchesLoading = 'loading';
        },
        [updateBranches.fulfilled]: (state, action) => {
            state.branchesLoading = 'success'
            state.website = action.payload
            localStorage.setItem('website', JSON.stringify(action.payload))
            notify('Филиал обновлен!')
        },
        [updateBranches.rejected]: (state, action) => {
            state.branchesLoading = 'error'
            notify(action.payload)
        },

        [deleteBranches.pending]: (state) => {
            state.branchesLoading = 'loading';
        },
        [deleteBranches.fulfilled]: (state, action) => {
            state.branchesLoading = 'success'
            state.website = action.payload
            localStorage.setItem('website', JSON.stringify(action.payload))
            notify('Филиал удалён!')
        },
        [deleteBranches.rejected]: (state, action) => {
            state.branchesLoading = 'error'
            notify(action.payload)
        },

        [editAboutPage.pending]: (state) => {
            state.editAboutPageLoading = 'loading';
        },
        [editAboutPage.fulfilled]: (state, action) => {
            state.editAboutPageLoading = 'success'
            state.website = action.payload
            localStorage.setItem('website', JSON.stringify(action.payload))
            notify('Страница обновлена!')
        },
        [editAboutPage.rejected]: (state, action) => {
            state.editAboutPageLoading = 'error'
            notify(action.payload)
        },
    }
});

export const {} = websiteSlice.actions;

export default websiteSlice.reducer;
