import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToCart } from 'store/slices/cartSlice';
import { ProductExample, CartIconBtn } from 'assets';
import CustomCounter from '../customCounter';
import './productCard.scss';
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

const ProductCard = ({ border = false, product }) => {
  const [productCounter, setProductCounter] = useState(0);
  const dispatch = useDispatch();
  const addToCartHandle = () => {
    dispatch(addToCart({ product, productCounter }));
    setProductCounter(0);
  };
  return (
    <div className='productCard' style={{ border: border ? '1px solid rgba(0, 0, 0, 0.1)' : '' }}>
      <Link
        to='/catalog/pismo&grafika/bumaga/bumaga-dlya-pechati/34533'
        className='productCard-img__wrap'
      >
          <div className="productCard-img">
              <LazyLoadImage
                  src={ProductExample}
                  width={178}
                  height={167}
                  alt="Фото продукта"
                  effect="opacity"
              />
          </div>
        {product.promoText && (
          <p
            className='productCard-promo'
            style={{ background: product.promoGreen ? '#74BE84' : 'red' }}
          >
            {product.promoText}
          </p>
        )}
      </Link>
      <div className='productCard-top'>
        {product.available ? (
          <span className='productCard-status' style={{ color: '#74BE84' }}>
            В наличии
          </span>
        ) : (
          <span className='productCard-status' style={{ color: 'red' }}>
            Нет в наличии
          </span>
        )}
        <Link
          to='/catalog/pismo&grafika/bumaga/bumaga-dlya-pechati/34533'
          className='productCard-name'
        >
          {product.name}
        </Link>
      </div>
      <h4 className={`productCard-price ${product.oldPrice && 'productCard-price--red'}`}>
        {product.price} c{' '}
        {product.oldPrice && <span className='productCard-price-old'>{product.oldPrice}с</span>}
      </h4>
      <div className='productCard-bottom'>
        <CustomCounter setProductCounter={setProductCounter} productCounter={productCounter} />
        {product.available && (
          <button className='productCard-btn btn-transitioned-animated' onClick={addToCartHandle}>
            в корзину
            <CartIconBtn />
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
