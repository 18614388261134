import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hideSearch, showSearch } from 'store/slices/searchSlice';
import { Logo, PhoneIconRed, ArrowIcon, ProfileIcon, ZoomIcon, MailIcon } from 'assets';
import HeaderCatalog from './components/headerCatalog';
import HeaderSearch from './components/headerSearch';
import HeaderCart from './components/headerCart';
import './header.scss';
import {getCartTotal} from "../../../store/slices/cartSlice";


const Header = () => {
  const [isBurger, setIsBurger] = useState(false);
  const toggleBurger = () => {
    setIsBurger(!isBurger)
    if (!isBurger) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  };
  const closeBurger = () => {
    setIsBurger(false)
    document.body.style.overflow = 'visible'
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { isSearchFocused } = useSelector((state) => state.search);
  const { website } = useSelector((state) => state.website);
  const dispatch = useDispatch();
  const handleShowSearch = () => {
    dispatch(showSearch(''));
  };
  const handleHideSearch = () => {
    dispatch(hideSearch());
  };

  useEffect(() => {
    dispatch(getCartTotal());
  },[])

  return (
    <>
      <header className={`header-top header__wrapper-burger ${isBurger ? 'burger' : ''}`}>
        <div
          className={`header-search-container header-search-container-top ${
            isSearchFocused && 'header-search-container--active'
          }`}
          onClick={handleHideSearch}
        ></div>
        <div className='container'>
          <div className='header-top__wrap'>
            <nav className='header-menu'>
              <NavLink
                to='/'
                onClick={closeBurger}
                className={({ isActive }) =>
                  `header-menu__link header-menu__link--mobile ${
                    isActive ? 'header-menu__link--active' : ''
                  }`
                }
              >
                Главная
              </NavLink>
              <NavLink
                to='catalog'
                onClick={closeBurger}
                className={({ isActive }) =>
                  `header-menu__link ${isActive ? 'header-menu__link--active' : ''}`
                }
              >
                Каталог
              </NavLink>
              <NavLink
                to='promotions'
                onClick={closeBurger}
                className={({ isActive }) =>
                  `header-menu__link ${isActive ? 'header-menu__link--active' : ''}`
                }
              >
                Акции
              </NavLink>
              <NavLink
                to='catalog/popular-products'
                onClick={closeBurger}
                className={({ isActive }) =>
                  `header-menu__link ${isActive ? 'header-menu__link--active' : ''}`
                }
              >
                Популярные товары
              </NavLink>
              <NavLink
                to='catalog/new'
                onClick={closeBurger}
                className={({ isActive }) =>
                  `header-menu__link ${isActive ? 'header-menu__link--active' : ''}`
                }
              >
                Новинки
              </NavLink>
              <NavLink
                to='news'
                onClick={closeBurger}
                className={({ isActive }) =>
                  `header-menu__link ${isActive ? 'header-menu__link--active' : ''}`
                }
              >
                Новости
              </NavLink>
              <NavLink
                to='about'
                onClick={closeBurger}
                className={({ isActive }) =>
                  `header-menu__link ${isActive ? 'header-menu__link--active' : ''}`
                }
              >
                О компании
              </NavLink>
              <NavLink
                to='contacts'
                onClick={closeBurger}
                className={({ isActive }) =>
                  `header-menu__link ${isActive ? 'header-menu__link--active' : ''}`
                }
              >
                Контакты
              </NavLink>

              <div className='show-on-mobile-only'>
                <div className='header-menu__categories'>
                  <h6 className='header-menu__categories-title'>Популярные категории</h6>
                  <div className='header-menu__categories-flex'>
                    <Link to='/' onClick={closeBurger} className='header-menu__categories-link'>
                      Письмо и графика
                    </Link>
                    <Link to='/' onClick={closeBurger} className='header-menu__categories-link'>
                      Бумага для печати
                    </Link>
                    <Link to='/' onClick={closeBurger} className='header-menu__categories-link'>
                      Товары для творчества
                    </Link>
                    <Link to='/' onClick={closeBurger} className='header-menu__categories-link'>
                      Ручки
                    </Link>
                    <Link to='/' onClick={closeBurger} className='header-menu__categories-link'>
                      Карандаши
                    </Link>
                    <Link to='/' onClick={closeBurger} className='header-menu__categories-link'>
                      Блокноты
                    </Link>
                    <Link to='/' onClick={closeBurger} className='header-menu__categories-link'>
                      Скетчбуки
                    </Link>
                  </div>
                </div>
                <Link to={`${isLoggedIn ? 'profile' : 'login' }`} onClick={closeBurger} className='header-menu__btn-login'>
                  <ProfileIcon />
                  {isLoggedIn ? 'Мой профиль' : 'Войти или зарегистрироваться' }
                </Link>
                <span className='header-menu__divider'></span>
              </div>
            </nav>
            <div className='header-top__right'>
              <a
                className='header-top__right-link header-top__right-link--mail'
                href='mailto:info@kanzler.kg'
              >
                <MailIcon />
                <span>info@Kanzler.kg</span>
              </a>
              <a
                className='header-top__right-link header-top__right-link--phone'
                href={`tel:${website?.mainNumber}`}
              >
                <PhoneIconRed />
                <span>Позвонить</span>
                <ArrowIcon />
              </a>
            </div>
            <div className='header-socials show-on-mobile-only'>
              <a
                target='_blank'
                href={website?.facebook}
                className='header-socials__link'
              >
                Facebook
              </a>
              <a
                target='_blank'
                href={website?.instagram}
                className='header-socials__link'
              >
                Instagram
              </a>
              <a
                target='_blank'
                href={`https://wa.me/${website?.whatsApp}`}
                className='header-socials__link'
              >
                What's App
              </a>
              <a
                target='_blank'
                href={website?.tiktok}
                className='header-socials__link'
              >
                Tiktok
              </a>
            </div>
          </div>
        </div>
      </header>
      <header
        className={`header-bottom ${scrollPosition > 0 && 'header-bottom--active'} ${
          isSearchFocused && 'header-bottom--search-mobile'
        }`}
      >
        <div className='container'>
          <div className='header-bottom__wrap'>
            <Link to='/' className='header-logo'>
              <img src={Logo} alt='Логотип' />
            </Link>
            <HeaderCatalog />
            <HeaderSearch />
            <div className='header-bottom__controls'>
              <a href={`tel:${website?.mainNumber}`} className='header-btn header-btn--phone'>
                <PhoneIconRed />
              </a>
              <button
                type='button'
                className='header-btn header-btn--search'
                onClick={handleShowSearch}
              >
                <ZoomIcon />
              </button>
              <HeaderCart />
              <Link to={`${isLoggedIn ? 'profile' : 'login' }`} type='button' className='header-btn header-btn--profile'>
                <ProfileIcon />
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div
        className={`header__burger-container ${isBurger ? 'burger' : ''}`}
        onClick={closeBurger}
      ></div>
      <div className={`header__burger ${isBurger ? 'burger' : ''}`} onClick={toggleBurger}>
        <span></span>
      </div>
    </>
  );
};

export default Header;
