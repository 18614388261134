import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import {axiosClient} from "../../axios/axiosClient";
import axios from "axios";
const notify = (text) => toast(text);

const initialState = {
    orders: [],
    oneOrders: null,
    loading: null,
    getAllOrdersLoading: null,
    getOneOrdersLoading: null
};

export const getAllOrders = createAsyncThunk(
    'orders/getAllOrders',
    async function({token}, { rejectWithValue }) {
        try {
            const response = await axiosClient.get(`/orders/get-all-orders`, {headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    }
);
export const getOneOrders = createAsyncThunk(
    'orders/getOneOrders',
    async function({id}, { rejectWithValue }) {
        try {
            const response = await axiosClient.get(`/orders/get-one-order/${id}`)
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    }
);
export const updateOrders = createAsyncThunk(
    'orders/updateOrders',
    async function ({token, id, name, surname, phone, email, whatsapp, person, shipment, country, city, street, house, apartment, comment}, { rejectWithValue  }) {
        try {

            const response = await axiosClient.put(`/orders/update/${id}`,{name, surname, phone, email, whatsapp, person, shipment, country, city, street, house, apartment, comment},{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);
export const deleteOrders = createAsyncThunk(
    'orders/deleteOrders',
    async function ({id, token, imagePublicId}, { rejectWithValue  }) {
        try {
            const response = await axiosClient.delete(`/orders/delete/${id}`,{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);



const ordersSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {},
    extraReducers: {
        [getAllOrders.pending]: (state) => {
            state.getAllOrdersLoading = 'loading';
        },
        [getAllOrders.fulfilled]: (state, action) => {
            state.getAllOrdersLoading = 'success'
            state.orders = action.payload
        },
        [getAllOrders.rejected]: (state, action) => {
            state.getAllOrdersLoading = 'error'
            notify(action.payload)
        },

        [getOneOrders.pending]: (state) => {
            state.getOneOrdersLoading = 'loading'
            state.oneOrders = null
        },
        [getOneOrders.fulfilled]: (state, action) => {
            state.getOneOrdersLoading = 'success'
            state.oneOrders = action.payload
        },
        [getOneOrders.rejected]: (state, action) => {
            state.getOneOrdersLoading = 'error'
            notify(action.payload)
        },

        [updateOrders.pending]: (state) => {
            state.loading = 'loading';
        },
        [updateOrders.fulfilled]: (state, action) => {
            state.loading = 'success'
            state.orders = state.orders.map(el => {
                if (el._id === action.payload._id) {
                    return {...el, ...action.payload}
                } else {
                    return el
                }
            })
            notify('Заказ обновлен!')
        },
        [updateOrders.rejected]: (state, action) => {
            state.loading = 'error'
            notify(action.payload)
        },

        [deleteOrders.pending]: (state) => {
            state.loading = 'loading';
        },
        [deleteOrders.fulfilled]: (state, action) => {
            state.loading = 'success'
            state.orders = state.orders.filter(el => el._id !== action.payload._id)
            notify('Заказ удалён!')
        },
        [deleteOrders.rejected]: (state, action) => {
            state.loading = 'error'
            notify(action.payload)
        },
    }
});

export const {} = ordersSlice.actions;

export default ordersSlice.reducer;
