import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPopularProducts, fetchProductsOnSale } from 'store/slices/productsSlice';
import Hero from './components/hero';
import Categories from './components/categories';
import PromotionsSlider from './components/promotionsSlider';
import News from './components/newsSlider';
import Branches from '../@components/branches';

import ProductCardSlider from '../@components/productCardSlider';
import Loader from "../@components/loader";
import './hero.scss';

const Home = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchPopularProducts());
        dispatch(fetchProductsOnSale());
    }, [dispatch]);

    const { popularProducts, popularProductsLoading, productsOnSale, productsOnSaleLoading } = useSelector((state) => state.products);

    return (
        <div className='homepage'>
            <Hero />
            <Categories />
            <PromotionsSlider />
            {
                popularProductsLoading === 'loading' ? <Loader withHeight={600}/> : (
                    popularProducts.length > 0 && (
                        <ProductCardSlider
                            title={'Популярные продукты'}
                            customClass={'section-slider-popular'}
                            products={popularProducts}
                        />
                    )
                )
            }
            {
                productsOnSaleLoading === 'loading' ? <Loader withHeight={600}/> : (
                    productsOnSale.length > 0 && (
                        <ProductCardSlider
                            title={'Акционные товары'}
                            customClass={'section-slider-promotionsSlider-products'}
                            cardBorder={true}
                            products={productsOnSale}
                        />
                    )
                )
            }
            <News />
            <Branches />
        </div>
    );
};

export default Home;
