import React from 'react';
import {useSelector} from "react-redux";
import Map from '../map';
import TitleSection from '../titleSection';
import { PhoneIcon, PhoneIconMobile } from 'assets';
import './branches.scss';
import {formatNumber} from "helpers/formatNumber";

const Branches = ({ showTitle = true }) => {
  const {website} = useSelector((state) => state.website)

  return (
    <div className='branches'>
      <div className='container'>
        {showTitle && <TitleSection>Филиалы</TitleSection>}
        <div className='branches-wrap'>
          <div className='branches-left'>
            <div className='branches-schedule'>
              <h5 className='branches-title branches-title--red'>Режим работы</h5>
              <p>Пн-Пт 9.00 - 18.00</p>
              <p>Сб 9.00 - 18.00</p>
              <p>Воскресенье выходной</p>
            </div>
            {
              website?.branches.length > 0 && website?.branches.map(el => (
                    <div className='branches-location' key={el?._id}>
                      <h5 className='branches-title'>{el?.address}</h5>
                      <a href={`tel:+996${formatNumber(el?.mobilePhone)}`}>
                        <PhoneIconMobile />
                        {el?.mobilePhone}
                      </a>
                      <a href={`tel:+996${formatNumber(el?.homePhone)}`}>
                        <PhoneIcon />
                        {el?.homePhone}
                      </a>
                    </div>
              ))
            }
          </div>
          <div className='branches-map'>
            <Map />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Branches;
