import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPopularProducts } from '../../store/slices/productsSlice';
import Accordion from 'react-bootstrap/Accordion';
import Breadcrumbs from '../@components/breadcrumbs';
import ProductCard from '../@components/productCard';
import CustomSelect from '../@components/customSelect';
import Loader from '../@components/loader';
import { ArrowIcon, ArrowIconBlack, CrossIcon } from 'assets';
import './catalog.scss';

const Catalog = ({ title }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPopularProducts());
  }, [dispatch]);
  const { popularProducts, popularProductsLoading, products } = useSelector((state) => state.products);

  const [isSidebarShow, setIsSidebarShow] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarShow(!isSidebarShow)
    if (!isSidebarShow) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  };

  const closeSidebar = () => {
    setIsSidebarShow(false)
    document.body.style.overflow = 'visible'
  }
  let location = useLocation();

  return (
    <div className='catalog'>
      <div className='container'>
        <div className='catalog-wrap'>
          <div className='catalog-left'>
            <Breadcrumbs />
            <div className={`catalog-filter ${isSidebarShow && 'catalog-filter--show'}`}>
              <div className='catalog-filter-header'>
                <p className='catalog-filter-header__title'>Категории</p>
                <button
                  type='button'
                  className='catalog-filter-header__btn'
                  onClick={closeSidebar}
                >
                  <CrossIcon />
                </button>
              </div>
              <Accordion alwaysOpen>
                {products.map((el, ind) => (
                  <Accordion.Item eventKey={ind} key={ind}>
                    <Accordion.Header>
                      {el.mainCat} <ArrowIconBlack />
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className='catalog-filter__subCat'>
                        <Link
                          to='/catalog/pismo&grafika'
                          className='catalog-filter__subCat-link'
                          onClick={closeSidebar}
                        >
                          Посмотреть все товары
                          <span>
                            (2520)
                          </span>
                        </Link>
                        <Accordion alwaysOpen>
                          {el.subCat.map((subCat, i) => (
                            <Accordion.Item eventKey={i} key={i}>
                              <Accordion.Header>
                                {subCat.catName} <ArrowIconBlack />
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className='catalog-filter__subSubCat'>
                                  <Link
                                    className='catalog-filter__subSubCat-link'
                                    to='/catalog/pismo&grafika/bumaga'
                                    onClick={closeSidebar}
                                  >
                                    Посмотреть все товары
                                    <span>
                                      (1220)
                                    </span>
                                  </Link>
                                  {subCat.subSubCat.map((subSubCat, index) => (
                                      <Link
                                          className='catalog-filter__subSubCat-link'
                                          to='/catalog/pismo&grafika/bumaga/bumaga-dlya-pechati'
                                          key={index}
                                          onClick={closeSidebar}
                                      >
                                        {
                                          subSubCat?.name
                                        }
                                        <span>
                                          (230)
                                        </span>
                                      </Link>
                                  ))}
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className={`catalog-filter__container ${isSidebarShow && 'catalog-filter__container--show'}`} onClick={closeSidebar}></div>
          </div>
          <div className='catalog-right'>
            <div className='catalog-categories'>
              {location.pathname !== '/catalog' && (
                <div className='catalog-categories__list'>
                  <Link to='#'>Ручки</Link>
                  <Link to='#'>Ручки</Link>
                  <Link to='#'>Ручки</Link>
                  <Link to='#'>Ручки</Link>
                </div>
              )}
            </div>
            <div className='catalog-banner'>
              <h4 className='catalog-banner__title'>
                Создай новогоднее настроение вместе с kanzler
              </h4>
              <Link className='catalog-banner__link' to='#'>
                подробнее
              </Link>
            </div>
            <div className='catalog-results'>
              <div className='catalog-results__header'>
                <div className='catalog-results__left'>
                  <h2 className='catalog-results__title'>{title}</h2>
                  <button
                    type='button'
                    className='catalog-results__btn-categories'
                    onClick={toggleSidebar}
                  >
                    Категории <ArrowIcon />
                  </button>
                </div>
                <div className='catalog-results__right'>
                  <p className='catalog-results__count'>1420 товара(-ов)</p>
                  <CustomSelect/>
                </div>
              </div>
              {popularProductsLoading === 'pending' ? (
                <Loader />
              ) : (
                <div className='catalog-results__grid'>
                  {popularProducts.map((product) => (
                    <ProductCard key={product.id} active border product={product} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
