import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";

const AuthLayoutProtected = () => {
    const {isLoggedIn} = useSelector((state) => state.auth)
    return (
        isLoggedIn ? <Outlet/> : <Navigate to="/login"/>
    );
};

export default AuthLayoutProtected;