import Provider1 from './1.png';
import Provider2 from './2.png';
import Provider3 from './3.png';
import Provider4 from './4.png';
import Provider5 from './5.png';
import Provider6 from './6.png';
import Provider7 from './7.png';
import Provider8 from './8.png';
import Provider9 from './9.png';
import Provider10 from './10.png';
import Provider11 from './11.png';
import Provider12 from './12.png';
import Provider13 from './13.png';
import Provider14 from './14.png';
import Provider15 from './15.png';
import Provider16 from './16.png';
import Provider17 from './17.png';
import Provider18 from './18.png';
import Provider19 from './19.png';
import Provider20 from './20.png';

export const providers = [
  {
    src: Provider1,
    width: 135,
    height: 34
  },
  {
    src: Provider2,
    width: 146,
    height: 28
  },
  {
    src: Provider3,
    width: 97,
    height: 69
  },
  {
    src: Provider4,
    width: 58,
    height: 69
  },
  {
    src: Provider5,
    width: 58,
    height: 69
  },
  {
    src: Provider6,
    width: 120,
    height: 48
  },
  {
    src: Provider7,
    width: 146,
    height: 58
  },
  {
    src: Provider8,
    width: 174,
    height: 70
  },
  {
    src: Provider9,
    width: 126,
    height: 50
  },
  {
    src: Provider10,
    width: 134,
    height: 54
  },
  {
    src: Provider11,
    width: 114,
    height: 46
  },
  {
    src: Provider12,
    width: 146,
    height: 58
  },
  {
    src: Provider13,
    width: 126,
    height: 50
  },
  {
    src: Provider14,
    width: 126,
    height: 50
  },
  {
    src: Provider15,
    width: 134,
    height: 54
  },
  {
    src: Provider16,
    width: 124,
    height: 49
  },
  {
    src: Provider17,
    width: 146,
    height: 57
  },
  {
    src: Provider18,
    width: 126,
    height: 49
  },
  {
    src: Provider19,
    width: 174,
    height: 69
  },
  {
    src: Provider20,
    width: 49,
    height: 69
  },
];
