import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {CrossIcon} from "assets";
import './modalShipment.scss'


const ModalShipment = ({ show, onHide, type}) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Body className='modal-shipment__body'>
                {type === 'courier' ? (
                    <>
                        <h5 className="modal-shipment__body-title">Курьерская доставка</h5>
                        <p className="modal-shipment__body-text">Вы можете заказать доставку товара с помощью курьера, который прибудет по указанному адресу с 10.00 до 21.00. Курьерская служба, после оформления заказа, свяжется с вами для уточнения адреса и предложит выбрать удобное время доставки.</p>
                        <p className="modal-shipment__body-text">Вы вскрываете упаковку при курьере, осматриваете на целостность и соответствие указанной комплектации.</p>
                    </>
                ) : (
                    <>
                        <h5 className="modal-shipment__body-title">Режим работы</h5>
                        <p className="modal-shipment__body-text"> Пн-Пт 9.00 - 18.00</p>
                        <p className="modal-shipment__body-text">Сб 9.00 - 18.00</p>
                        <p className="modal-shipment__body-text">Воскресенье выходной</p>
                        <h5 className="modal-shipment__body-title mt-4">Самовывоз из магазина</h5>
                        <p className="modal-shipment__body-text">Вы можете забрать товар в нашем магазине в любое удобное для вас время с 10:00 до 21:00. Для этого необходимо предоставить консультанту номер заказа и личные данные, указанные при заказе в интернет-магазине.</p>
                    </>
                )}
                <button type='button' className='modal-shipment__body-btn-close' onClick={() => onHide()}>
                    <CrossIcon />
                </button>
            </Modal.Body>
        </Modal>
    );
};

export default ModalShipment;
