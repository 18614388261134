export default [
  {
    mainCat: 'Письмо и графика',
    subCat: [
      {
        catName: 'Бумага',
        subSubCat: [
          {
            name: 'Бумага для печати',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Для акварели',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Ручки',
        subSubCat: [
          {
            name: 'Бумага для печати',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Для акварели',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Карандаши',
        subSubCat: [
          {
            name: 'Бумага для печати',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Для акварели',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Твёрдая бумага2',
        subSubCat: [
          {
            name: 'Бумага для печати',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Для акварели',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары1',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары2',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары3',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары4',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары5',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары6',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары7',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары8',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары9',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары10',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары11',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары12',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары13',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары14',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары15',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
  {
    mainCat: 'Художественные товары16',
    subCat: [
      {
        catName: 'Кисточки',
        subSubCat: [
          {
            name: 'Кисточки из лисьего хвоста',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Кисточки для масла',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки1',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки2',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки3',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
          {
            name: 'Большие, широкие кисточки4',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
          
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски1',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски2',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски3',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
      {
        catName: 'Краски4',
        subSubCat: [
          {
            name: 'Маслянные краски',
            items: [
              {
                name: 'Цветная бумага Aristotel',
                price: '240',
                available: true,
              },
              {
                name: 'Цветная бумага Bonk',
                price: '340',
                available: false,
              },
            ],
          },
          {
            name: 'Краски для стен',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
          {
            name: 'Фетровая',
            items: [],
          },
        ],
      },
    ],
  },
];
