export default [
  {
    id: '1',
    code: 'L434-GH43',
    name: 'Цветная бумага Aristotel Цветная бумага Aristotel',
    available: true,
    price: '240',
    oldPrice: '350',
    promoText: 'Советуем',
    promoGreen: true,
  },
  {
    id: '2',
    code: 'L434-GH43',
    name: 'Карандаши Aristotel Цветные',
    available: true,
    price: '500',
    promoText: '20%',
    promoGreen: false,
  },
  {
    id: '3',
    code: 'L434-GH43',
    name: 'Цветная бумага Aristotel',
    available: true,
    price: '240',
    oldPrice: '350',
    promoText: 'Советуем',
    promoGreen: true,
  },
  {
    id: '4',
    code: 'L434-GH43',
    name: 'Цветная бумага Aristotel',
    available: false,
    price: '240',
    oldPrice: '350',
    promoText: 'Советуем',
    promoGreen: true,
  },
  {
    id: '5',
    code: 'L434-GH43',
    name: 'Цветная бумага Aristotel',
    available: true,
    price: '240',
    oldPrice: '350',
    promoText: 'Советуем',
    promoGreen: true,
  },
  {
    id: '6',
    code: 'L434-GH43',
    name: 'Цветная бумага Aristotel',
    available: true,
    price: '240',
    oldPrice: '350',
    promoText: 'Советуем',
    promoGreen: true,
  },
];
