import React, { useEffect, useState } from 'react';
import {useSelector} from "react-redux";
import { ArrowIcon, ArrowIconRight, SquaresIcon } from 'assets';
import HeaderCatalogCol from './components/headerCatalogCol';
import './headerCatalog.scss';


const HeaderCatalog = () => {
  const [isCatalogOpen, setIsCatalogOpen] = useState(false);
  const openCatalog = () => setIsCatalogOpen(true);
  const closeCatalog = () => setIsCatalogOpen(false);

  const {products} = useSelector((state) => state.products)

  const [activeCat, setActiveCat] = useState(products[0].mainCat);
  const [isAnimated, setIsAnimated] = useState(false);
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    if (isCatalogOpen && !isDisplayed) {
      setIsDisplayed(true);
      const timeout = setTimeout(() => {
        setIsAnimated(true);
      }, 100);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      if (isDisplayed) {
        setIsAnimated(false);
        const timeout = setTimeout(() => {
          setIsDisplayed(false);
        }, 300);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [isCatalogOpen]);

  const manageActiveCat = (catName) => setActiveCat(catName);

  const getHeaderCatalogColumn = (array, start) => {
    let content = [];
    for (let i = start; i < array.length; i += 3) {
      const item = array[i];
      content.push(<HeaderCatalogCol cat={item} key={i} closeCatalog={closeCatalog} />);
    }
    return content;
  };

  return (
    <>
      <div
        className='header-catalog-trigger'
        onMouseEnter={openCatalog}
        onMouseLeave={closeCatalog}
      >
        <button
          type='button'
          className={`header-catalog-btn ${isCatalogOpen && 'header-catalog-btn--active'}`}
        >
          <SquaresIcon />
          <span>Каталог</span>
          <ArrowIcon className='header-btn--catalog-arrow' />
        </button>
        <div
          className={`header-catalog ${isAnimated && 'header-catalog--animated'}`}
          style={{ display: isDisplayed ? 'block' : 'none' }}
        >
          <div className='container'>
            <div className='header-catalog__wrap'>
              <div className='header-catalog__sidebar'>
                <div className='header-catalog__sidebar-content'>
                  {products.map((el, ind) => (
                    <button
                      type='button'
                      key={ind}
                      className={`header-catalog__sidebar-btn ${
                        activeCat === el?.mainCat && 'header-catalog__sidebar-btn--active'
                      }`}
                      onClick={() => manageActiveCat(el?.mainCat)}
                    >
                      <span>
                        {el?.mainCat} <ArrowIconRight />
                      </span>
                    </button>
                  ))}
                </div>
              </div>
              <div className='header-catalog__right'>
                <div className='header-catalog__content'>
                  {products.map((el, ind) => (
                    <div
                      className={`header-catalog__category ${
                        activeCat === el?.mainCat && 'header-catalog__category--active'
                      }`}
                      key={ind}
                      data-cat={el?.mainCat}
                    >
                      <div className='header-catalog__category-col'>
                        {getHeaderCatalogColumn(el?.subCat, 0)}
                      </div>
                      <div className='header-catalog__category-col'>
                        {getHeaderCatalogColumn(el?.subCat, 1)}
                      </div>
                      <div className='header-catalog__category-col'>
                        {getHeaderCatalogColumn(el?.subCat, 2)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`header-catalog-bg ${isAnimated && 'header-catalog-bg--animated'}`}
        style={{ display: isDisplayed ? 'block' : 'none' }}
      ></div>
    </>
  );
};

export default HeaderCatalog;
