import React, { useState } from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logOut} from "store/slices/authSlice";
import Breadcrumbs from '../@components/breadcrumbs';
import TitleSection from '../@components/titleSection';
import ProfileInfo from './components/profileInfo';
import ProfileEdit from './components/profileEdit';
import ProfileHistory from './components/profileHistory';
import './profile.scss';


const Profile = () => {
  const [isEditProfile, setIsEditProfile] = useState(false);
  const toggleEditProfile = () => setIsEditProfile(!isEditProfile);

  const dispatch = useDispatch()
  const handleLogOut = () => {
    dispatch(logOut())
  }
  const {user} = useSelector((state) => state.auth)

  return (
    <>
      <div className='profile'>
        <div className='container'>
          <Breadcrumbs />
          <TitleSection redLine={false}>Мой аккаунт</TitleSection>
          {isEditProfile ? (
            <ProfileEdit toggleEditProfile={toggleEditProfile} user={user}/>
          ) : (
            <>
              <ProfileInfo toggleEditProfile={toggleEditProfile} user={user}/>
              <div className="profile-btn__wrap">
                {
                  user?.isAdmin && <Link className="profile-btn-exit profile-btn-admin" to="/admin">Админка</Link>
                }
                <button className="profile-btn-exit" type="button" onClick={handleLogOut}>Выйти</button>
              </div>
              <ProfileHistory/>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
