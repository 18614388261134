import React, {useEffect, useState} from 'react';
import {Navigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changePassword, validateChangePasswordToken} from "store/slices/authSlice";

import '../auth.scss'
import Loader from "../../@components/loader";
import Form from "react-bootstrap/Form";
import {EyeIconHide, EyeIconShow} from "../../../assets";


const ChangePassword = () => {
    const [queryParameters] = useSearchParams()
    const dispatch = useDispatch()
    const {validateChangePasswordTokenLoading, changePasswordLoading} = useSelector((state) => state.auth)

    useEffect(() => {
        if (queryParameters.get("token")) {
            dispatch(validateChangePasswordToken({token: queryParameters.get("token")}))
        }
    },[])

    const [password, setPassword] = useState('')
    const [showPass, setShowPass] = useState(false)

    const togglePasswordShow = () => setShowPass(!showPass)

    const changePasswordHandler = (e) => {
        e.preventDefault()
        if (changePasswordLoading !== 'loading') {
            dispatch(changePassword({token: queryParameters.get("token"), password}))
        }
    }

    return (
        <div className="container auth-account-activation" style={{minHeight: '300px'}}>
            {
                validateChangePasswordTokenLoading === 'loading' ? (
                    <Loader withHeight={300}/>
                ) : validateChangePasswordTokenLoading === 'success' ? (
                    <>
                        <form onSubmit={changePasswordHandler}>
                            <Form.Group className="auth-form__password" controlId="formBasicPassword">
                                <Form.Label>Пароль</Form.Label>
                                <Form.Control
                                    type={showPass ? 'text' : 'password'}
                                    placeholder="Введите пароль"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required={true}
                                />
                                <button type="button" className="auth-form__password-btn" onClick={togglePasswordShow}>{showPass ? <EyeIconShow/> : <EyeIconHide/>}</button>
                            </Form.Group>
                            <button type="submit" className="auth-form__btn-submit mt-2">
                                Отправить
                            </button>
                        </form>
                        {changePasswordLoading === 'loading' && <Loader fullScreen={true}/>}
                        {changePasswordLoading === 'success' && <Navigate to={'/login'}/>}
                    </>
                ) : (
                    <h3 className="auth-account-activation__title">Ссылка просрочена. Попробуйте ещё раз.</h3>
                )
            }
        </div>
    );
};

export default ChangePassword;