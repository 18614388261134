import React, {lazy, Suspense, useEffect} from 'react';
import {Route, Routes, useNavigate, useRoutes} from "react-router-dom";
import Layout from "./pages/@components/layout";
import Home from "./pages/home";
import Catalog from "./pages/catalog";
import ProductOnePage from "./pages/productOnePage";
import News from "./pages/news";
import NewsOnePage from "./pages/newsOnePage";
import Promotions from "./pages/promotions";
import Login from "./pages/auth/login";
import Signup from "./pages/auth/signup";
import Profile from "./pages/profile";
import Cart from "./pages/cart";
import About from "./pages/about";
import Contacts from "./pages/contacts";
import TermsOfUse from "./pages/termsOfUse";
import NoPage from "./pages/noPage";
import AuthLayout from "./pages/auth/authLayout";
import AuthLayoutProtected from "./pages/auth/authLayoutProtected";
import AccountActivation from "./pages/auth/accountActivation";
import ChangePassword from "./pages/auth/changePassword";
import ChangeEmailConfirmation from "./pages/auth/changeEmailConfirmation";
import OrderDetails from "./pages/orderDetails";

// import Admin from "./pages/admin";
// import AdminNews from "./pages/admin/components/adminNews";
// import AdminHome from "./pages/admin/components/adminHome";
// import CreateEditNews from "./pages/admin/components/adminNews/components/createEditNews";
// import AdminSales from "./pages/admin/components/adminSales";
// import CreateEditSales from "./pages/admin/components/adminSales/components/createEditSales";


import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "./helpers/ErrorFallback";
import Loader from "./pages/@components/loader";
import {useDispatch, useSelector} from "react-redux";
import {websiteGetIsChangedToken, websiteInit} from "./store/slices/websiteSlice";


const Admin1 = lazy(() => import('./pages/admin'))
const AdminNews1 = lazy(() => import('./pages/admin/components/adminNews'))
const AdminHome1 = lazy(() => import('./pages/admin/components/adminHome'))
const CreateEditNews1 = lazy(() => import('./pages/admin/components/adminNews/components/createEditNews'))
const AdminSales1 = lazy(() => import('./pages/admin/components/adminSales'))
const CreateEditSales1 = lazy(() => import('./pages/admin/components/adminSales/components/createEditSales'))
const AdminPromocodes = lazy(() => import('./pages/admin/components/adminPromocodes'))
const CreateEditPromocodes = lazy(() => import('./pages/admin/components/adminPromocodes/components/createEditPromocodes'))
const AdminBranches = lazy(() => import('./pages/admin/components/adminBranches'))
const CreateEditBranches = lazy(() => import('./pages/admin/components/adminBranches/components/createEditBranches'))
const AdminOrders = lazy(() => import('./pages/admin/components/adminOrders'))
const EditOrders = lazy(() => import('./pages/admin/components/adminOrders/components/editOrders'))
const AdminAboutPage = lazy(() => import('./pages/admin/components/adminAboutPage'))


const mainCatsByName = { 'pismo&grafika': 'Письмо и графика' };
const CatsByName = { bumaga: 'Бумага' };
const SubCatsByName = { 'bumaga-dlya-pechati': 'Бумага для печати' };
const ProductsByName = { 34533: 'e-motion Pure Black fountain pen, M, black' };
const DynamicMainCategoryBreadcrumb = ({ match }) => {
    return <span>{mainCatsByName[match.params.mainCategory]}</span>;
};
const DynamicCategoryBreadcrumb = ({ match }) => {
    return <span>{CatsByName[match.params.category]}</span>;
};
const DynamicSubCategoryBreadcrumb = ({ match }) => {
    return <span>{SubCatsByName[match.params.subCategory]}</span>;
};
const DynamicProductBreadcrumb = ({ match }) => {
    return <span>{ProductsByName[match.params.product]}</span>;
};

export const routesAll = [
    {
        path: '/',
        element: <Layout />,
        children: [
            { index: true, element: <Home />, breadcrumb: 'Главная' },
            { path: 'catalog', element: <Catalog title={'Каталог'} />, breadcrumb: 'Каталог' },
            {
                path: 'catalog/search-results',
                element: <Catalog title={'Результаты поиска'} />,
                breadcrumb: 'Результаты поиска',
            },
            {
                path: 'catalog/popular-products',
                element: <Catalog title={'Популярные товары'} />,
                breadcrumb: 'Популярные товары',
            },
            { path: 'catalog/new', element: <Catalog title={'Новинки'} />, breadcrumb: 'Новинки' },
            {
                path: 'catalog/:mainCategory',
                element: <Catalog title={'Письмо и графика'} />,
                breadcrumb: DynamicMainCategoryBreadcrumb,
            },
            {
                path: 'catalog/:mainCategory/:category',
                element: <Catalog title={'Бумага'} />,
                breadcrumb: DynamicCategoryBreadcrumb,
            },
            {
                path: 'catalog/:mainCategory/:category/:subCategory',
                element: <Catalog title={'Бумага для печати'} />,
                breadcrumb: DynamicSubCategoryBreadcrumb,
            },
            {
                path: 'catalog/:mainCategory/:category/:subCategory/:product',
                element: <ProductOnePage />,
                breadcrumb: DynamicProductBreadcrumb,
            },
            { path: 'news', element: <News />, breadcrumb: 'Новости' },
            { path: 'news/:id', element: <NewsOnePage />, breadcrumb: 'Новость' },
            { path: 'promotions', element: <Promotions />, breadcrumb: 'Акции' },
            {
                path: '/', children: [
                    { path: 'login', element: <Login />},
                    { path: 'signup', element: <Signup />},
                    { path: 'account-activation', element: <AccountActivation/>},
                    { path: 'change-password', element: <ChangePassword/>}
                ], element: <AuthLayout/>
            },
            {
                path: '/', children: [
                    { path: 'profile', element: <Profile />, breadcrumb: 'Мой аккаунт' },
                ], element: <AuthLayoutProtected/>
            },
            { path: 'change-email-confirmation', element: <ChangeEmailConfirmation/>},
            { path: 'cart', element: <Cart />, breadcrumb: 'Корзина' },
            { path: 'terms-of-use', element: <TermsOfUse /> },
            { path: 'about', element: <About />, breadcrumb: 'О компании' },
            { path: 'contacts', element: <Contacts />, breadcrumb: 'Контакты' },
            { path: '*', element: <NoPage /> },
        ],
    },
    // {
    //     path: '/admin',
    //     element: <Admin/>,
    //     children: [
    //         { path: '/admin', element: <AdminHome /> },
    //         { path: 'news', element: <AdminNews /> },
    //         { path: 'news/create', element: <CreateEditNews/> },
    //         { path: 'news/edit/:id', element: <CreateEditNews/> },
    //         { path: 'sales', element: <AdminSales/> },
    //         { path: 'sales/create', element: <CreateEditSales/> },
    //         { path: 'sales/edit/:id', element: <CreateEditSales/> },
    //     ],
    // },
];


const RoutesAll = () => {
    const navigate = useNavigate()

    const {isChangedToken, website} = useSelector(state => state.website)

    const dispatch = useDispatch()
    useEffect( () => {
        dispatch(websiteGetIsChangedToken())
    },[])

    useEffect(() => {
        if (!website && isChangedToken) {
            dispatch(websiteInit())
        } else if (website && isChangedToken && isChangedToken !== website?.isChangedToken) {
            dispatch(websiteInit())
        }
    },[isChangedToken])

    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Home/>}/>
                <Route path="catalog" element={<Catalog/>}/>
                <Route path="catalog/search-results" element={<Catalog/>}/>
                <Route path="catalog/popular-products" element={<Catalog/>}/>
                <Route path="catalog/new" element={<Catalog/>}/>
                <Route path="catalog/:mainCategory" element={<Catalog/>}/>
                <Route path="catalog/:mainCategory/:category" element={<Catalog/>}/>
                <Route path="catalog/:mainCategory/:category/:subCategory" element={<Catalog/>}/>
                <Route path="catalog/:mainCategory/:category/:subCategory/:product" element={<ProductOnePage/>}/>
                <Route path="news" element={<News/>}/>
                <Route path="news/:id" element={<NewsOnePage/>}/>
                <Route path="promotions" element={<Promotions/>}/>
                <Route path="about" element={<About/>}/>
                <Route path="contacts" element={<Contacts/>}/>
                <Route path="terms-of-use" element={<TermsOfUse/>}/>
                <Route path="cart" element={<Cart/>}/>
                <Route path="/" element={<AuthLayout/>}>
                    <Route path="login" element={<Login/>}/>
                    <Route path="signup" element={<Signup/>}/>
                    <Route path="account-activation" element={<AccountActivation/>}/>
                    <Route path="change-password" element={<ChangePassword/>}/>
                </Route>
                <Route path="/" element={<AuthLayoutProtected/>}>
                    <Route path="profile" element={<Profile/>}/>
                </Route>
                <Route path="change-email-confirmation" element={<ChangeEmailConfirmation/>}/>
                <Route path="order-details/:id" element={<OrderDetails/>}/>
                <Route path="*" element={<NoPage/>}/>
            </Route>
            <Route path="/admin" element={
                <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onReset={() => navigate('/')}
                >
                    <Suspense fallback={<div style={{background: '#f2f7ff'}}><Loader fullScreen={true}/></div>}>
                        <Admin1/>
                    </Suspense>
                </ErrorBoundary>
            }>
                <Route index element={ <AdminHome1/> }/>
                <Route path="news" element={ <AdminNews1/> }/>
                <Route path="news/create" element={ <CreateEditNews1/> }/>
                <Route path="news/edit/:id" element={ <CreateEditNews1/> }/>
                <Route path="sales" element={ <AdminSales1/> }/>
                <Route path="sales/create" element={ <CreateEditSales1/> }/>
                <Route path="sales/edit/:id" element={ <CreateEditSales1/> }/>
                <Route path="promocodes" element={ <AdminPromocodes/> }/>
                <Route path="promocodes/create" element={ <CreateEditPromocodes/> }/>
                <Route path="promocodes/edit/:id" element={ <CreateEditPromocodes/> }/>
                <Route path="branches" element={ <AdminBranches/> }/>
                <Route path="branches/create" element={ <CreateEditBranches/> }/>
                <Route path="branches/edit/:id" element={ <CreateEditBranches/> }/>
                <Route path="orders" element={ <AdminOrders/> }/>
                <Route path="orders/edit/:id" element={ <EditOrders/> }/>
                <Route path="about" element={ <AdminAboutPage/> }/>

                <Route path="*" element={<NoPage/>}/>
            </Route>
        </Routes>
    )
    // return useRoutes(routesAll);
};

export default RoutesAll;