import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {axiosClient} from "../../axios/axiosClient";

const notify = (text) => toast(text);
const cartInLocalStorage = JSON.parse(localStorage.getItem("cart"));

const initialState = {
  cart: cartInLocalStorage ? cartInLocalStorage : [],
  totalQuantity: 0,
  totalPrice: 0,
  confirmOrderLoading: null,
};

export const confirmOrder = createAsyncThunk(
    'cart/confirmOrder',
    async function ({user, name, surname, phone, phoneAddon, email, whatsapp, person, shipment, country, city, street, apartment, house, comment, promocode, adminEmail}, { rejectWithValue, getState  }) {
      const state = getState()
      try {
        const response = await axiosClient.post('/orders/confirm', {
          products: state?.cart?.cart,
          totalQuantity: state?.cart?.totalQuantity,
          totalPrice: state?.cart?.totalPrice,
          user, name, surname, phone, phoneAddon, email, whatsapp, person, shipment, country, city, street, apartment, house, comment, promocode, adminEmail
        })
        if (response?.data) {
          return response?.data
        }
      } catch (error) {
        return rejectWithValue(error.response.data.error || error.message);
      }
    },
);


const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      if (action.payload.productCounter > 0) {
        let find = state.cart.findIndex((item) => item.id === action.payload.product.id);
        if (find >= 0) {
          state.cart[find].quantity += action.payload.productCounter;
          state.totalQuantity += action.payload.productCounter;
        } else {
          state.cart.push({
            ...action.payload.product,
            quantity: action.payload.productCounter,
          });
          state.totalQuantity += action.payload.productCounter;
        }
        localStorage.setItem('cart', JSON.stringify(state.cart))
        notify('Товар добавлен в корзину');
      } else {
        notify('Укажите количество товара');
      }
    },
    getCartTotal: (state) => {
      let { totalQuantity, totalPrice } = state.cart.reduce(
        (cartTotal, cartItem) => {
          const { price, quantity } = cartItem;
          const itemTotal = price * quantity;
          cartTotal.totalPrice += itemTotal;
          cartTotal.totalQuantity += quantity;
          return cartTotal;
        },
        {
          totalPrice: 0,
          totalQuantity: 0,
        },
      );
      state.totalPrice = parseInt(totalPrice.toFixed(2));
      state.totalQuantity = totalQuantity;
    },
    removeItem: (state, action) => {
      state.cart = state.cart.filter((item) => item.id !== action.payload);
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    changeItemQuantity: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.id === action.payload.productId) {
          return { ...item, quantity: action.payload.productCounter };
        }
        return item;
      });
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    clearCart: (state) => {
      state.cart = []
      state.totalQuantity = 0
      state.totalPrice = 0
      localStorage.removeItem('cart')
    }
  },
  extraReducers: {
    [confirmOrder.pending]: (state) => {
      state.confirmOrderLoading = 'loading';
    },
    [confirmOrder.fulfilled]: (state) => {
      state.confirmOrderLoading = 'success'
    },
    [confirmOrder.rejected]: (state, action) => {
      state.confirmOrderLoading = 'error'
      notify(action.payload)
    },
  }
});

export const { addToCart, getCartTotal, removeItem, changeItemQuantity, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
